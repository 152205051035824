<template>
  <div class="d-flex align-items-center" style="min-height: 100vh">
    <div class="col-sm-8 col-md-6 col-lg-6 mx-auto" style="min-width: 300px">
      <div class="row mt-5 mb-5" v-if="getCurrentSchool">
        <div class="d-flex justify-content-center col-6 align-items-center border-right border-secondary py-2">
          <div>
            <b-img :src="getCurrentSchool.logo_url" width="120" :alt="getCurrentSchool.name" />
          </div>
        </div>

        <div class="d-flex justify-content-center col-6 align-items-center">
          <div>
            <b-img :src="logo" width="150" :alt="brand" />
          </div>
        </div>
      </div>
      <div class="text-center mt-5 mb-5" v-else>
        <b-img :src="logo" width="200" :alt="brand" />
      </div>

      <card class="navbar-shadow">
        <slot />
        <div class="text-center">
          <b> Student Sign Up </b>
        </div>
        <div class="text-center" style="font-size: 12px">Request an Account</div>
        <b-alert class="my-4" show variant="light">
          <i class="material-icons ml-0 mr-1" style="font-size: 20px">info</i>Please make sure not to request multiple
          accounts. If you already have an account, you can simply
          <router-link to="/login"> <b class="text-dark"> login </b> </router-link>
          with your email address or
          <router-link to="/forgot-password">
            <b class="text-dark"> reset your password </b>
          </router-link>
          .</b-alert
        >
        <b-form @submit.prevent="onSubmit">
          <b-form-group label-for="firstname" label-class="form-label">
            <template #label> First Name <span style="color: red">*</span> </template>
            <b-form-input
              id="firstname"
              :placeholder="$t('userMsgs.firstName')"
              v-model.trim="form.firstName"
              :state="!$v.form.firstName.required && $v.form.$dirty ? false : null"
            />
            <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label-for="lastname" label-class="form-label">
            <template #label> Last Name <span style="color: red">*</span> </template>
            <b-form-input
              id="lastname"
              :placeholder="$t('userMsgs.lastName')"
              v-model.trim="form.lastName"
              :state="!$v.form.lastName.required && $v.form.$dirty ? false : null"
            />
            <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label-for="email" label-class="form-label">
            <template #label> Email Address <span style="color: red">*</span> </template>
            <b-form-input
              id="email"
              :placeholder="$t('authMsgs.emailAddr')"
              type="email"
              v-model.trim="form.email"
              :state="(!$v.form.email.required || !$v.form.email.email) && $v.form.$dirty ? false : null"
            />
            <b-form-invalid-feedback v-if="!$v.form.email.email">
              Invalid email. Please make sure that it's in correct format i.e.
              test@example.com.</b-form-invalid-feedback
            >
            <b-form-invalid-feedback v-else>This field is required.</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label-for="phone" label-class="form-label">
            <template #label> Phone <span style="color: red">*</span> </template>
            <b-form-input
              id="phone"
              placeholder="(888) 689 - 1235"
              v-model="form.phone"
              v-mask="'(###) ### - ####'"
              :state="
                (!$v.form.phone.required || !$v.form.phone.isCorrectFormat) && $v.form.phone.$dirty ? false : null
              "
            />
            <b-form-invalid-feedback v-if="!$v.form.phone.required">This field is required.</b-form-invalid-feedback>
            <b-form-invalid-feedback v-else>
              Invalid phone number. Please make sure that it's in correct format i.e. (XXX) XXX - XXXX.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group v-if="mainAppUrl" label-for="npass" label-class="form-label">
            <template #label> New Password <span style="color: red">*</span> </template>
            <b-form-input
              id="npass"
              name="password"
              placeholder="Password"
              v-model="form.password"
              :required="!!form.repeatPassword"
              :type="fieldTypes.password"
              autocomplete="off"
              @focus="handlePassType"
              @blur="handlePassType"
              :state="
                (!$v.form.password.required || !$v.form.password.isCorrectFormat) && $v.form.password.$dirty
                  ? false
                  : null
              "
            />
            <b-form-invalid-feedback v-if="!$v.form.password.required">This field is required.</b-form-invalid-feedback>
            <b-form-invalid-feedback v-else>{{ $t(`passValidationMsgs.${passErrorCode}`) }}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group v-if="mainAppUrl" label-for="repeatPassword" label-class="form-label">
            <template #label> Repeat Password <span style="color: red">*</span> </template>
            <b-form-input
              id="repeatPassword"
              name="repeatPassword"
              :placeholder="$t('profileMsgs.repeatPassword')"
              v-model="form.repeatPassword"
              :required="!!form.password"
              :type="fieldTypes.repeatPassword"
              autocomplete="off"
              @focus="handlePassType"
              @blur="handlePassType"
              :state="
                (!$v.form.repeatPassword.required || !$v.form.repeatPassword.isCorrectFormat) &&
                $v.form.repeatPassword.$dirty
                  ? false
                  : null
              "
            />
            <b-form-invalid-feedback v-if="!$v.form.repeatPassword.required"
              >This field is required.</b-form-invalid-feedback
            >
            <b-form-invalid-feedback v-else>{{ $t(`passValidationMsgs.${repPassErrorCode}`) }}</b-form-invalid-feedback>
          </b-form-group>

          <div class="my-3">
            <re-captcha @getToken="getToken" class=""> </re-captcha>
            <span v-if="!isTokenAvailable" style="font-size: 13px" class="text-danger"
              >Please fill recaptcha before submit.</span
            >
          </div>

          <!-- <b-form-group :label="$t('authMsgs.password')" label-for="password" label-class="form-label">
            <fmv-input-group-merge
              id="password"
              v-model="form.password"
              :state="errorState"
              :placeholder="$t('authMsgs.password')"
              type="password"
              class="form-control-prepended"
              required
              prepend
            >
              <span class="fas fa-key" />
            </fmv-input-group-merge>
          </b-form-group> -->

          <div v-if="error" class="alert alert-danger mt-3" role="alert">
            Cannot create an account with the given credentials. Please contact administrator.
          </div>

          <div class="form-group">
            <b-btn type="submit" variant="primary" block :disabled="isLoading" style="height: 2.3rem">
              <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
              <span v-else>Request Account</span>
            </b-btn>
          </div>

          <!-- Text Place (Use if needed!) -->
          <!-- <div class="text-center">
            <component
              :is="linkComponent"
              :to="routeTo('forgot-password')"
              class="text-black-70"
              style="text-decoration: underline"
              v-text="`${$t('authMsgs.forgotPass')}?`"
            />
          </div> -->
        </b-form>

        <div slot="footer" class="text-center text-black-50">
          <div class="d-flex justify-content-center mt-0">
            Already have an account?
            <a class="ml-1">
              <router-link :to="{ name: 'login' }" style="text-decoration: underline"> Login</router-link></a
            >
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import Page from '@/components/Page';
import ReCaptcha from './ReCaptcha.vue';
import generator from 'generate-password';
import Card from '@/components/Ui/Card.vue';
import { titleize } from '../../common/utils';
import { mapGetters, mapActions } from 'vuex';
import logo from '@/assets/images/logos/logo_blue.png';
import { required, email } from 'vuelidate/lib/validators';
import {
  PASSWORD_ALL_NUMERIC_REGEX,
  PASSWORD_VALIDATIONS,
  PHONE_FORMAT_REGEX,
  DASHBOARD_TYPES,
} from '../../common/constants';
import { requiredIf } from 'vuelidate/lib/validators';

export default {
  components: {
    Card,
    ReCaptcha,
  },
  extends: Page,
  name: 'StudentSignUp',
  layout: 'blank',

  props: {
    brand: { type: String, default: () => 'Transition' },
    logo: { type: String, default: () => logo },
    linkComponent: {
      type: [String, Object],
      default: () => 'b-link',
    },
  },

  data() {
    return {
      title: 'Student Sign Up',
      form: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        repeatPassword: '',
      },
      errorStates: { password: null, phone: null },
      passErrorCode: null,
      repPassErrorCode: null,

      error: false,
      isLoading: false,
      fieldTypes: { password: 'text', repeatPassword: 'text' },
      captchaToken: '',
      isTokenAvailable: true,
    };
  },
  validations() {
    return {
      form: {
        firstName: { required },
        lastName: { required },
        email: { required, email },
        password: {
          required: requiredIf(() => this.mainAppUrl),
          isCorrectFormat(value) {
            if (!this.mainAppUrl) return true;
            if (value.length < 5) {
              this.passErrorCode = PASSWORD_VALIDATIONS.PASSWORDS_MIN_LENGTH;
              return false;
            } else if (PASSWORD_ALL_NUMERIC_REGEX.test(value)) {
              this.passErrorCode = PASSWORD_VALIDATIONS.PASSWORDS_ALL_NUMERIC;
              return false;
            } else if (value !== this.form.repeatPassword) {
              this.passErrorCode = PASSWORD_VALIDATIONS.PASSWORDS_NOT_MATCH;
              return false;
            }
            return true;
          },
        },
        repeatPassword: {
          required: requiredIf(() => this.mainAppUrl),
          isCorrectFormat() {
            if (!this.mainAppUrl) return true;
            if (this.form.password !== this.form.repeatPassword) {
              this.repPassErrorCode = PASSWORD_VALIDATIONS.PASSWORDS_NOT_MATCH;
              return false;
            }
            return true;
          },
        },
        phone: {
          required,
          isCorrectFormat(value) {
            return PHONE_FORMAT_REGEX.test(value);
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUserDashboard']),
    ...mapGetters('school', ['getCurrentSchool']),
    errorState() {
      return this.error ? false : undefined;
    },
    mainAppUrl() {
      return window.location.host === process.env.VUE_APP_MAIN_DOMAIN;
    },
  },
  mounted() {
    if (!this.isLoggedIn) {
      this.setSettings({ layout: { layout: 'blank' } });
    }
  },

  methods: {
    ...mapActions('settings', ['setSettings']),
    ...mapActions('signUpRequest', ['stdDirectSignup', 'stdSignupReq']),
    handlePassType(event) {
      const { srcElement, type } = event;
      const { name, value } = srcElement;

      if (type === 'blur' && !value) {
        this.fieldTypes[name] = 'text';
      } else {
        this.fieldTypes[name] = 'password';
      }
    },
    getToken(token) {
      this.captchaToken = token;
      this.isTokenAvailable = true;
    },
    getErrors(key) {
      if (this.errors && this.errors[key] !== undefined) {
        return this.errors[key];
      }
    },
    async onSubmit() {
      let pass;
      if (
        window.location.origin !== process.env.VUE_APP_MAIN_DOMAIN &&
        window.location.host !== process.env.VUE_APP_MAIN_DOMAIN
      ) {
        pass = generator.generate({ length: 10, numbers: true, strict: true });
      }

      this.isLoading = true;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.captchaToken) {
          this.isTokenAvailable = true;

          let config = {
            headers: {
              'X-Recaptcha-Token': this.captchaToken,
            },
          };

          try {
            const data = {
              email: this.form.email,
              first_name: titleize(this.form.firstName),
              last_name: titleize(this.form.lastName),
              password: this.form.password || pass,
              phone: this.form.phone,
              ...(window.location.host !== process.env.VUE_APP_MAIN_DOMAIN && { school: this.getCurrentSchool.id }),
            };

            if (this.getLoggedInUserDashboard !== DASHBOARD_TYPES.ORGANIZATION) {
              if (window.location.host !== process.env.VUE_APP_MAIN_DOMAIN) {
                await this.stdSignupReq({ data, config });
              } else {
                await this.stdDirectSignup({ data, config });
                await this.$store.dispatch('auth/login', {
                  email: this.form.email,
                  password: this.form.password || pass,
                });
                await this.$store.dispatch('student/getMyStudentProfile');
                this.$store.dispatch('student/getPiiStatus');
                this.setSettings({ layout: { layout: 'fixed' } });
                this.$router.push({ name: 'student-onboarding' });
              }
            }

            this.$router.push({ name: 'login' });
          } catch (_e) {
            this.error = true;
          }
        } else {
          this.isTokenAvailable = false;
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    },
  },
};
</script>
